<template>
    <div class="panel is-info">
        <div class="panel-heading is-relative is-align-content-baseline">
            <span class="has-text-centered">アップロード済みカタログ</span>
            <button v-if="!isEditMode" class="button is-info is-light edit-icon" @click="isEditMode = true">
                <i class="fas fa-edit" aria-hidden="true"></i>
                <small>編集</small>
            </button>
            <button v-else class="button is-light is-light edit-icon" @click="updateCatalogInfo">
                <i class="fas fa-check" aria-hidden="true"></i>
                <small>完了</small>
            </button>
        </div>
        <div v-if="!isEditMode" class="is-flex is-flex-direction-row is-justify-content-center is-flex-wrap-wrap">
            <catalog-image v-for="x of currentCatalogs" :key="x.catalogName" :srcUrl="x.srcUrl"
                :catalogName="x.catalogName" :isAvailable="x.isAvailable" class="m-2" />
        </div>
        <div v-else class="is-flex is-flex-direction-row is-justify-content-center is-flex-wrap-wrap">
            <edit-catalog v-for="x of currentCatalogs" :key="x.catalogName" :srcUrl="x.srcUrl"
                :catalogName="x.catalogName" :isAvailable="x.isAvailable" :timestamp="x.timestamp_create_at"
                class="m-2" />
        </div>
    </div>
</template>
<script>
import axios from "axios"
import CatalogImage from "@/components/CatalogImage.vue"
import EditCatalog from "@/components/EditCatalog.vue"

export default {
    name: "UploadedCatalogs",
    components: {
        CatalogImage,
        EditCatalog
    },
    data() {
        return {
            isEditMode: false,
            currentCatalogs: []
        }
    },
    mounted() {
        this.updateCatalog()
    },
    methods: {
        updateCatalog() {
            let builderInfo = {}
            this.$cognito.getAttributes()
                .then(result => {
                    for (var v of result) {
                        builderInfo[v.getName()] = v.getValue()
                    }
                    axios.post("https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/get-catalog-list", { sub: builderInfo["sub"] }).then((res) => {
                        console.log(res)
                        this.currentCatalogs = res.data.catalogs
                    }).catch((err) => {
                        console.log(err)
                    })
                })
        },
        updateCatalogInfo() {
            this.isEditMode = false
            setTimeout(() => this.updateCatalog(), 1000)
            return
        }
    }
}
</script>
<style scoped>
.edit-icon {
    position: absolute;
    margin-right: 1rem;
    right: 0;
    top: 0.5em;
}
</style>