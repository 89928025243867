<template>
    <div class="is-flex is-flex-direction-column is-justify-content-center panel is-flex-wrap-wrap is-relative catalog-image"
        :class="{ fade_layer: isDeleted }">
        <button v-if="!isDeleted" class="delete delete-button" @click="isDeleted = true"></button>
        <button v-else class="button undo-button" @click="isDeleted = false"><span>
                <i class="fas fa-undo"></i></span></button>
        <img :src="srcUrl" class="image has-ratio mx-auto" :class="{ is_fade_img: isDeleted }" />
        <small class="my-1">カタログ名</small>
        <input class="input is-small mx-auto mb-1 new-catalog-name-input" type="text" :placeholder="catalogName"
            v-model="newCatalogName">
        <small class="my-1">ステータス</small>
        <div class="mx-auto">
            <label class="radio">
                <input type="radio" :name="catalogName" v-model="newIsAvailable" value="受付中">
                受付中
            </label>
            <label class="radio">
                <input type="radio" :name="catalogName" v-model="newIsAvailable" value="受付停止中">
                受付停止中
            </label>
        </div>
    </div>
</template>
<script>
import axios from "axios"
export default {
    name: "EditCatalog",
    props: {
        srcUrl: String,
        catalogName: String,
        isAvailable: Boolean,
        timestamp: Number
    },
    computed: {
        newIsAvailableBoolean() {
            return this.newIsAvailable == "受付中" ? true : false
        }
    },
    data() {
        return {
            newCatalogName: this.catalogName,
            newIsAvailable: this.isAvailable ? "受付中" : "受付停止中",
            isDeleted: false
        }
    },
    beforeUnmount() {
        if (this.isDeleted) {
            this.deleteCatalog()
        } else {
            this.updateCatalog()
        }
    },
    methods: {
        updateCatalog() {
            if (this.catalogName != this.newCatalogName || this.isAvailable != this.newIsAvailableBoolean) {
                let builderInfo = {}
                this.$cognito.getAttributes()
                    .then(result => {
                        for (var v of result) {
                            builderInfo[v.getName()] = v.getValue()
                        }
                    }).then(x => {
                        axios.post("https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/update-catalog", { sub: builderInfo.sub, timestamp: this.timestamp, catalogName: this.newCatalogName, isAvailable: this.newIsAvailableBoolean })
                            .catch((err) => {
                                console.log(x)
                                console.log(err)
                            })
                    })
            }
        },
        deleteCatalog() {
            let builderInfo = {}
            this.$cognito.getAttributes()
                .then(result => {
                    for (var v of result) {
                        builderInfo[v.getName()] = v.getValue()
                    }
                }).then(x => {
                    axios.post("https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/delete-catalog", { sub: builderInfo.sub, timestamp: this.timestamp, srcUrl: this.srcUrl })
                        .catch((err) => {
                            console.log(x)
                            console.log(err)
                        })
                })

        }
    }
}

</script>
<style scoped>
.catalog-image {
    max-width: 30%;
}

.delete-button {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    z-index: 100;
}

.new-catalog-name-input {
    width: 90%;
}

.fade_layer {
    background-color: #000000;
    opacity: 0.5;
    z-index: 50;
}

.is_fade_img {
    filter: grayscale(1)
}

.undo-button {
    font-size: 1px;
    color: darkgreen;
    display: inline-flex;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    z-index: 100;
    border-radius: 9999px;
    height: 20px;
    width: 20px;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
}

.undo-button::before {
    content: "";
    height: 2px;
    width: 50%;
    display: block;
    left: 50%;
    position: absolute;
}

.undo-button::after {
    content: "";
    height: 2px;
    width: 50%;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
}
</style>