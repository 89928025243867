<template>
    <div class="panel is-success main-panel">
        <p class="panel-heading">カタログ新規アップロード</p>
        <div class="is-flex is-flex-direction-column is-justify-content-start main-panel">
            <div class="field p-2 is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                <label class="label ml-6">カタログ表示名</label>
                <div class="control ml-6">
                    <input class="input" type="text" placeholder="総合カタログ" v-model="catalogName">
                </div>
            </div>

            <div class="field p-2 is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                <label class="label ml-6 mr-5">ステータス</label>
                <div class="radio-group ml-6">
                    <label class="radio">
                        <input type="radio" name="question" value="受付中" v-model="isAvailable">
                        受付中
                    </label>
                    <label class="radio">
                        <input type="radio" name="question" value="受付停止中" v-model="isAvailable">
                        受付停止中
                    </label>
                </div>
            </div>
            <div class="field p-2 is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                <label class="label ml-6 mr-4">カタログ画像</label>
                <div class="drop-area ml-6 py-4 has-text-centered" :class="{ enter: isDragEnter }"
                    @dragenter="dragEnter" @dragleave="dragLeave" @dragover.prevent="dragOver" @drop.prevent="dropFile"
                    @dropover.prevent>
                    <label class="file-label" id="file-upload-button">
                        <input class="file-input" type="file" name="resume" @change="onChange" />
                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-select-label">
                                ファイルを選択
                            </span>
                        </span>
                    </label>
                    <p v-if="files.length !== 1" class="drop-label"><small>またはドロップ</small></p>
                    <p v-else class="filename"><span><i class="fas fa-file"></i></span>{{ files[0].name }}
                    <span class="remove-image__icon"><button class="button is-danger is-light" @click="removeFile()"><i class="fas fa-trash"></i></button></span></p>
                </div>
            </div>

            <div class="field">
                <div class="control">
                    <button class="button is-primary send-button" :class="{ 'is-loading': isLoading }"
                        @click="submitNewCatalog">アップロード</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios"
export default {
    name: "CatalogUpload",
    data() {
        return {
            isDragEnter: false,
            files: [],
            catalogName: "",
            isAvailable: null,
            builderInfo: {},
            isLoading: false
        }
    },
    methods: {
        removeFile(){
            this.files = []
            this.isDragEnter = false
        },
        dragEnter() {
            this.isDragEnter = true
        },
        dragLeave(e) {
            if (e.target.className == "file-input" || e.target.className == "fas fa-upload" || e.target.className == "file-label" || e.target.className == "file-cta" || e.target.className == "file-select-label" || e.target.className == "drop-label" || e.target.className.indexOf("drop-area") != -1) {
                return
            } else {
                this.isDragEnter = false
            }
        },
        dragOver() {
            return
        },
        dropFile(event) {
            if (event.dataTransfer.files.length > 1) {
                alert("登録できる画像は一枚のみです。")
                this.isDragEnter = false
                return
            } else {
                this.files = [...event.dataTransfer.files]
                if((!this.files[0].name.endsWith(".jpg"))&&(!this.files[0].name.endsWith(".jpeg"))&&(!this.files[0].name.endsWith(".png"))){
                    alert("アップロード可能なファイル形式は.jpgもしくは.jpegまたは.pngのみです。")
                    this.files = []
                    this.isDragEnter = false
                    return
                }
                return
            }
        },
        validate() {
            //カタログ名の確認
            if (this.catalogName == "") {
                alert("カタログ名が未入力です。")
                return false
            }
            //ステータスの確認
            if (this.isAvailable === null) {
                alert("ステータスが未入力です。")
                return false
            }
            //ファイルの確認
            if (this.files.length == 0) {
                alert("カタログ画像が選択されていません。")
                return false
            } else if (this.files.length > 1) {
                alert("カタログ画像は1枚のみです。")
                console.log("here")
                return false
            }
            return true
        },
        submitNewCatalog() {
            this.isLoading = true
            if (!this.validate()) {
                this.isLoading = false
                return
            }
            let builderInfo = {}
            this.$cognito.getAttributes()
                .then(result => {
                    for (var v of result) {
                        builderInfo[v.getName()] = v.getValue()
                    }
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        const base64Image = event.currentTarget.result
                        const imageJson = {
                            sub: builderInfo["sub"],
                            image: base64Image,
                            catalogName: this.catalogName,
                            isAvailable: this.isAvailable
                        }
                        console.log(imageJson)
                        axios.post("https://hg1ref5qvb.execute-api.ap-northeast-1.amazonaws.com/prd/register-catalog", imageJson).then((res) => {
                            console.log(res)
                            this.isLoading = false
                            this.$emit("updateCatalogList", true)
                        }).catch(() => {
                            alert("カタログのアップロードに失敗しました。再度ログインしてお試しください。")
                            this.isLoading = false
                        })
                    }
                    reader.readAsDataURL(this.files[0])
                })
                .catch((err) => {
                    if(err.message=="User is not authenticated"){
                        alert("長時間操作がなかったため接続がタイムアウトしました。再度ログインしてください。")
                    }else{
                        alert("認証エラーが発生しました。再度ログインしてください。")
                    }
                    this.isLoading = false
                    try {
                        this.$cognito.logout()
                    }finally{
                        this.$router.push({path: "/login"})   
                    }
                })
        },
        onChange(event) {
            this.files = event.target.files;
        },

    }
}
</script>
<style scoped>
.main-panel {
    padding-bottom: 16px;
}
.label:not(:last-child) {
    margin-bottom: 0;
}

.send-button {
    margin-right: auto;
}

.drop-area {
    color: gray;
    font-weight: bold;
    font-size: 1.2em;
    width: 50%;
    height: auto;
    border: 3px solid gray;
    border-radius: 15px;
    border-style: dotted;
    display: block;
}

#file-upload-button {
    display: block;

}

.file-select-label {
    display: inline-block;
}

span .file-select-label {
    pointer-events: inherit
}

.input[type="file"] {
    display: none;
}

.enter {
    border: 3px dotted powderblue;
}
.filename {
    margin-top: 8px;
    overflow-wrap: break-word;
}
.drop-label {
    margin-top: 8px;
}
.remove-image__icon{
    float: right;
}
</style>
