<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <uploaded-catalogs ref="child" />
            </div>
            <div class="column">
                <catalog-upload @updateCatalogList="updateCatalogList" />
            </div>
        </div>
    </div>
</template>
<script>
import UploadedCatalogs from "@/components/UploadedCatalogs.vue"
import CatalogUpload from "@/components/CatalogUpload.vue"

export default {
    name: "CatalogUploadView",
    components: {
        UploadedCatalogs,
        CatalogUpload
    },
    methods: {
        updateCatalogList() {
            this.$refs.child.updateCatalog()
        }
    }
}
</script>

<style scoped>
.edit-icon {
    position: absolute;
    right: 2em;
}
</style>>
