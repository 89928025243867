<template>
    <div class="is-flex is-flex-direction-column is-justify-content-center panel is-flex-wrap-wrap catalog-image">
        <img :src="srcUrl" class="image has-ratio mx-auto" />
        <small>{{ catalogName }}</small>
        <small v-if="isAvailable">
            <span class="icon has-text-success">
                <i class="fas fa-check-square"></i>
            </span>
            <span>受付中</span>
        </small>
        <small v-else>
            <span class="icon has-text-danger">
                <i class="fas fa-ban"></i>
            </span>
            <span>受付停止中</span>
        </small>
    </div>


</template>
<script>
export default {
    name: "CatalogImage",
    props: {
        srcUrl: String,
        catalogName: String,
        isAvailable: Boolean,
        isEditMode: Boolean
    }
}
</script>
<style scoped>
.catalog-image {
    max-width: 30%;
}
</style>